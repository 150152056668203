
import { Options, Vue } from "vue-class-component";
import { IGoal } from "@/types/Goal";
import WorkForceService from "@/shared/application/work-force-service-proxy";
import Breadcrumb from "@/components/Breadcrumb.vue";
import moment from "moment";
import PaginationComponent from "@/components/PaginationComponent.vue";
import Filter from "@/components/Filter.vue";
import { IEmployeeList } from "@/types/EmployeeList";
import { tr } from "date-fns/locale";

@Options({
  name: "Goals",
  components: {
    Breadcrumb,
    PaginationComponent,
    Filter,
  },
})
export default class Goals extends Vue {
  protected workForceService: WorkForceService = new WorkForceService();
  goals: IGoal[] = [];
  page = 0;
  perPage = 15;
  totalCount = 0;
  currentPage = 0;
  employeeId = 0;
  crumbs: any = ["Dashboard", "Employees", "Goals"];

  get totalPages() {
    return Math.ceil(this.totalCount / this.perPage);
  }

  savedSort = null;
  async sort(sortBy: any) {
    this.savedSort = sortBy;
    return this.workForceService
      .get<IEmployeeList[]>(
        `/goals?SortBy=${sortBy}&PageIndex=${this.currentPage}&PageSize=${this.perPage}&EmployeeId=${this.$route.params.id}`,
        false
      )
      .then((response: any) => {
        this.goals = response.content.items;
        this.totalCount = response.content.totalCount;
      });
  }

  goToPage(page: number) {
    this.currentPage = page;
    return this.workForceService
      .get<IGoal[]>(
        `/goals?PageIndex=${page}&PageSize=${this.perPage}&EmployeeId=${this.$route.params.id}`,
        false
      )
      .then((response: any) => {
        return (this.goals = response.content.items);
      });
  }

  async initialize(empId: any) {
    await this.workForceService
      .get<IGoal[]>(
        `/goals?PageIndex=${this.page}&PageSize=${this.perPage}&EmployeeId=${empId}`,
        false
      )
      .then((response: any) => {
        this.totalCount = response.content.totalCount;
        this.goals = response.content.items;
      });
  }

  async created() {
    this.employeeId = +this.$route.params.id;
    await this.initialize(this.$route.params.id);
  }

  formateDate(leaveDate: any) {
    return moment(new Date(leaveDate)).format("Do-MMM-YY");
  }

  async goToRoute(route: string) {
    await this.$router.push(route);
  }

  canEdit(date: any) {
    if (new Date() > new Date(date)) {
      return false;
    }
    return true;
  }
}
